import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(VSelect, {
        staticClass: "mt-5",
        attrs: {
          outlined: "",
          "item-value": "value",
          "item-text": "text",
          items: _vm.languageOptionsValueByText,
          label: _vm.$t("language"),
        },
        on: { change: _vm.handleChangeLanguage },
        model: {
          value: _vm.selectedLanguage,
          callback: function ($$v) {
            _vm.selectedLanguage = $$v
          },
          expression: "selectedLanguage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }